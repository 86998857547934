import { AnyAction } from 'redux';

// Type import
import { AuthActionTypes, ISessionData } from './types';

export function resumeSessionRequest(token: string): AnyAction {
  return {
    type: AuthActionTypes.RESUME_SESSION_REQUEST,
    payload: {
      token,
    },
  };
}

export function signInSuccess(data: ISessionData, persist: boolean): AnyAction {
  return {
    type: AuthActionTypes.SIGN_IN_SUCCESS,
    payload: {
      data,
      persist,
    },
  };
}

export function sessionRefreshRequest(): AnyAction {
  return {
    type: AuthActionTypes.SESSION_REFRESH_REQUEST,
    payload: {},
  };
}

export function signFailure(): AnyAction {
  return {
    type: AuthActionTypes.SIGN_FAILURE,
    payload: {},
  };
}

export function signOut(): AnyAction {
  return {
    type: AuthActionTypes.SIGN_OUT,
    payload: {},
  };
}
